<template>
  <site-layout has-slider>
    <v-main>
        <head-slider></head-slider>
        <v-row>
          <v-col><title-bar title="NIEUWS" :first="true" class="mt-0">
            <template #right>
              <div class="d-flex align-center">
                <a href="https://www.facebook.com/framevastgoed" target="_blank"><v-icon large color="black" class="mr-3">mdi-facebook</v-icon></a>
                <a href="https://www.instagram.com/framevastgoed/" target="_blank"><v-icon large color="black" class="mr-3">mdi-instagram</v-icon></a>
                <a href="https://www.linkedin.com/company/framevastgoed/" target="_blank"><v-icon large color="black">mdi-linkedin</v-icon></a>
              </div>

            </template>
          </title-bar></v-col>
        </v-row>
      <v-row class="newsrow" v-if="newsLoading">
        <v-col cols="12" md="4" v-for="n in 3" :key="n">
          <v-skeleton-loader type="article, list-item-two-line"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="newsrow" v-else>
        <v-col v-for="(item, idx) in newsItems" :key="idx" cols="12" md="4">
          <post-item
              :caption="item.title"
              :title="item.subtitle"
              :to="{name: 'ViewPost', params: {type: $route.params.type, slug: item.id}}"
              :class="newsItemClass"
              :id="item.id"
          >
            {{ item.excerpt }}
          </post-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col><title-bar :title="$t('pleased')"></title-bar></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <div class="postimg-container">
            <v-img :src="require('@/assets/Frame kantoor.jpg')" :aspect-ratio="16/9" width="100%" min-width="100%" class="postimg"></v-img>
          </div>
        </v-col>
          <v-col cols="12" sm="6">
            <section-start></section-start>
            <span class="text-h6">FRAME</span>
            <titled-paragraph :title="$t('pleased_header')">
              {{ $t('pleased_text') }}
            </titled-paragraph>
          </v-col>
      </v-row>

      <v-row>
        <v-col><title-bar :title="$t('werkwijze')"></title-bar></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <section-start></section-start>
          <span class="text-h6">FRAME</span>
          <titled-paragraph :title="$t('werkwijze_header')">
            {{ $t('werkwijze_text')}}
          </titled-paragraph>
          <!-- <a href="#">{{ $t('lees_verder').toUpperCase() }}</a> -->
        </v-col>
        <v-col cols="12" sm="6">
          <div class="postimg-container">
            <v-img :src="require('@/assets/Frame werkwijze.jpg')" :aspect-ratio="16/9" width="100%" min-width="100%" class="postimg"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </site-layout>
</template>

<script>

import SiteLayout from "../layouts/SiteLayout";
import TitleBar from "../components/TitleBar";
import TitledParagraph from "../components/TitledParagraph";
import SectionStart from "../components/SectionStart";
import HeadSlider from "../components/HeadSlider";
import PostItem from "../components/PostItem";
import http from "../http";

export default {
  name: 'Home',
  components: {
    HeadSlider,
    SectionStart,
    TitledParagraph,
    SiteLayout, TitleBar, PostItem
  },
  computed: {
    newsItemClass() {
      return this.$vuetify.breakpoint.mdAndUp ? ["news-bordered pr-4"] : [""]
    }
  },
  data() {
    return {
      newsLoading: true,
      newsItems: []
    }
  },
  async mounted() {
    let resp = await http.get(`/p/${this.$route.params.locale}/nieuws?count=3&order=created_at`);
    this.newsItems = resp.data.data;
    this.newsLoading = false;
  }
}
</script>

<style>
  .newsrow :not(:last-child) .news-bordered, .newsrow :not(:last-child) .v-skeleton-loader {
    border-right: 1px solid black;
    border-radius: 0;
  }

  a {
    color: black !important;
    text-decoration: none;
  }
</style>
