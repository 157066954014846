<template>
  <v-card flat class="rounded-0">
    <router-link :to="to" v-if="src">
    <div class="postimg-container clickable">
      <v-img :aspect-ratio="16/9" width="100%" :src="src" class="postimg"></v-img>
    </div>
    </router-link>
    <div class="mt-6">
      <section-start></section-start>
      <span class="text-h6 d-block black--text" style="line-height: 1.1rem"><line-clamp lines="2">{{ caption }}</line-clamp></span>
      <div class="paragraph-header black--text mt-4"><line-clamp lines="1">{{ title }}</line-clamp></div>
      <p><line-clamp lines="4"><slot></slot></line-clamp></p>
      <router-link :to="{name: 'ViewPost', params: {type: 'nieuws', slug: id}}">LEES VERDER</router-link>
    </div>
  </v-card>
</template>

<script>
import SectionStart from "./SectionStart";
import LineClamp from "./LineClamp";
export default {
  name: "PostItem",
  components: {LineClamp, SectionStart},
  props: ['caption', 'title', 'src', 'to', 'id']
}
</script>

<style scoped>

</style>
