<template>
  <div>
    <template v-if="loading">
      <div style="height: 585px;position: relative;">
        <v-overlay :value="loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
      </div>
    </template>
    <v-carousel v-else hide-delimiter-background hide-delimiters :height="sliderHeight" cycle>
      <v-carousel-item v-for="(slide, idx) in slides" :key="idx"
                       style="position:relative;"
                       :src="slide.featured_image"
      >
        <v-row class="d-flex align-end" style="height: 100%">
          <v-col cols="12" class="text-left ml-md-16 ml-6">
            <div class="text-h4 white--text text-md-h3" style="font-size: 2rem;letter-spacing: 2px !important;text-transform: uppercase;max-width: 650px;">
              {{ slide.title }}
            </div>
            <div class="text-h3 white--text" style="font-size: 2rem;letter-spacing: 2px !important;"></div>
            <div class="white--text mt-3 text-h6" style="letter-spacing: 1px !important;">{{ slide.subtitle }}</div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import http from "@/http";
export default {
  name: "HeadSlider",
  data() {
    return {
      loading: true,
      slides: []
    }
  },
  methods: {

  },
  computed: {
    sliderHeight() {
      if (this.$vuetify.breakpoint.xs)
        return '240px';
      return '585px';
    }
  },
  async mounted() {
    let resp = await http.get(`/p/nl/slides`);
    this.slides = resp.data.data;
    this.loading = false;
  }
}
</script>
