<template>
  <div style="width:30px;height:5px;background-color:black;margin:0 0 12px;border-radius: 0"></div>
</template>

<script>
export default {
  name: "sectionStart"
}
</script>

<style scoped>

</style>
