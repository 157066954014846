<template>
  <v-card flat>
    <span class="paragraph-header" v-text="title"></span>
    <p><slot></slot></p>
  </v-card>
</template>

<script>
export default {
  name: "TitledParagraph",
  props: ['title']
}
</script>

<style scoped>

</style>