<template>
  <span class="clamped" :style="{'-webkit-line-clamp': lines}"><slot></slot></span>
</template>

<script>
export default {
  name: "LineClamp",
  props: ['lines']
}
</script>

<style scoped>
  .clamped {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
  }
</style>
