import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from "../i18n";

Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/post/:type',
//     name: 'Post',
//     component: () => import(/* webpackChunkName: "aanbod" */ '../views/Post')
//   },
//   {
//     path: '/post/:type/:slug',
//     name: 'ViewPost',
//     component: () => import(/* webpackChunkName: "aanbod" */ '../views/ViewPost')
//   },
//   {
//     path: '/contact',
//     name: 'Contact',
//     component: () => import(/* webpackChunkName: "contact" */ '../views/Contact')
//   },
// ]

const routes = [
  {
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    props: (route) => ({ locale: route.params.locale || 'nl' }),
    beforeEnter: (to, from, next) => { // <------------
      const locale = to.params.locale; // 1
      const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','); // 2
      if (!supported_locales.includes(locale)) return next('nl'); // 3
      if (i18n.locale !== locale) { // 4
        i18n.locale = locale;
      }
      return next() // 5
    },
    children: [
        {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: 'post/:type',
    name: 'Post',
    component: () => import(/* webpackChunkName: "aanbod" */ '../views/Post'),
  },
  {
    path: 'post/:type/:slug',
    name: 'ViewPost',
    component: () => import(/* webpackChunkName: "aanbod" */ '../views/ViewPost')
  },
  {
    path: 'contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact')
  },
    ]
  },
  { // <---------------
    path: '*',
    redirect() {
      return "nl";
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0};
  }
})

export default router
